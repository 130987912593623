.main__userprofile {
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  
}
@media screen and (min-width: 768px){
  .main__userprofile {
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
  }
}

@media screen and (min-width: 992px){
  
}

.profile__card {
  background-color: rgb(255, 255, 255,40%);
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.17,
    0.67,
    0.12,
    1.53
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.3s; /* or: Xms */
  margin-bottom: 15px;
}
.profile__card .profile__image {
  width: 100px;
  height: 100px;
  border-radius:  10% 0  0 10%;
  overflow: hidden;
  -webkit-border-radius:  10% 0  0 10%;
  -moz-border-radius:  10% 0  0 10%;
  -ms-border-radius:  10% 0  0 10%;
  -o-border-radius:  10% 0  0 10%;
}
.profile__card .profile__image img {
  object-fit: cover;
  max-width: 100%;
}
.profile__card p {
  margin-bottom: 0;
  margin-left: 10px;
  font-weight: bolder;
}
.username{
  word-wrap: break-word;
  width: 100px;
  text-align: center;
}
@media screen and (min-width: 768px){
  .username{
    width: 200px;
  }
  .profile__card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}