.avatarE {
  width: 100%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .avatarE {
    width: 60%;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

.avatarE img {
  border-radius: 50%;
  max-width: 100%;
  object-fit: cover;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.avatarE img:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.corssal div:first-child button {
  background: #fff;
  font-size: 30px;
}
@media (max-width: 768px) {
  .corssal div:first-child button:last-child,
  .corssal div:first-child button:first-child {
    display: none;
  }
}
.corssal div:first-child button:hover {
  background: transparent;
  color: #0081cf;
}
.rec .rec-pagination button{
  background-color: rgb(203, 219, 236);
}
.sel {
  border: 3px solid black;
}
