body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
#root {
  min-height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/c3.0aeb6c0b.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.__main {
  max-width: 100vw;
  border-radius: 10px;
  display: flex;
}
@media screen and (min-width: 768px) {
  #root {
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__main {
    max-width: 90%;
    margin: auto;
    min-height: 80vh;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}
.btn {
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease-in-out;
  font-weight: bolder;
}
.btn:hover {
  background-color: #00c9c8;
  color: #fff;
  transform: scale(1.02);
}
.btn i {
  flex: 0.2 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  border-right: 1px solid #ebe7fb;
}
.btn span {
  flex: 0.8 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c84ff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3b5bfe;
}
.slection-title {
  width: 100%;
  padding: 1vw;
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}

.main__chatbody {
  flex-grow: 1;
  background-color:rgb(244, 243, 248,40%);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  color: white;
}
@media screen and (min-width: 768px){
  .main__chatbody {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 992px){
  
}

@media screen and (min-width: 1200px){

}

.main__chatlist {
  margin-bottom: 20px;
  border-bottom: 2px solid #858588;
  
}
.main__chatlist.open .chatlist__items {
  display: block;
}
@media screen and (min-width: 768px) {
  .main__chatlist {
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
    border-bottom: none;
    min-height: 71vh;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}
.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #080808;
  outline: none;
}
.clicked {
  background: hsl(0, 81%, 69%);
  color: #fff;
}
.chatlist__items {
  overflow: auto;
  display: none;
  height: auto;
  max-height: 30vh;
}
.empty_message_show {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242425;
  font-size: larger;
  font-weight: bolder;
  padding: 30px;
}
@media screen and (min-width: 768px) {
  .chatlist__items {
    min-height: calc(100vh - calc(100vh / 2));
    max-height: calc(100vh - calc(100vh / 2));
  }
}

@media screen and (max-width: 768px) {
  .empty_message_show {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #ebe7fb;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  -webkit-animation-name: showIn;
          animation-name: showIn;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; /* or: Xms */
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal; /* or: normal */
  -webkit-animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  );
          animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; /* or: backwards, both, none */
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; /* or: Xms */
}
@-webkit-keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: bolder;
  font-size: 17px;
  margin-bottom: 3px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
  font-weight: bolder;

}
.chatlist__item:hover,
.chatlist__item.active {
  background: #fff;
  border-radius: 10px;
}
.chatlist__item.new {
  background: #a82929;
  border-radius: 10px;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.main__chatcontent {
  flex-grow: 1;
}
@media screen and (min-width: 768px){
  .main__chatcontent {
    flex-grow: 1;
    padding: 0px 40px;
  }
}

@media screen and (min-width: 992px){
  
}
.content__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebe7fb;
}
.current-chatting-user {
  display: flex;
  align-items: center;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings .btn-nobg {
  color: #000;
}
.content__body {
  height: calc(70vh);
}
.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  -webkit-animation-name: showIn;
          animation-name: showIn;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; /* or: Xms */
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal; /* or: normal */
  -webkit-animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  );
          animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; /* or: backwards, both, none */
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; /* or: Xms */
}
@-webkit-keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chat__item .avatar {
  margin-right: 0px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}
.chat__item__content {
  background-color: #4462ff;
  color: #fff;
  padding: 15px;
  border-radius: 10px 10px 0 10px;
  max-width: 50%;
  min-width: 215px;
}
.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
.chat__item__content .chat__meta span {
  font-size: 14px;
  color: #8693d3;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chat__msg {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}
.chat__item.other .chat__item__content {
  background-color: #fff;
  color: #000;
  border-radius: 10px 10px 10px 0;
  max-width: 50%;
}
.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}
.chat__item.other .chat__item__content .chat__meta span {
  color: #d1d1d1;
}
.content__footer {
  padding-top: 30px;
}
.sendNewMessage {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
}
.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4665ff;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.sendNewMessage button:hover {
  transform: scale(1.2);
}
.sendNewMessage button i {
  display: block;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}
#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}

.main__userprofile {
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  
}
@media screen and (min-width: 768px){
  .main__userprofile {
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
  }
}

@media screen and (min-width: 992px){
  
}

.profile__card {
  background-color: rgb(255, 255, 255,40%);
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: scale(0);
  -webkit-animation-name: showIn;
          animation-name: showIn;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; /* or: Xms */
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal; /* or: normal */
  -webkit-animation-timing-function: cubic-bezier(
    0.17,
    0.67,
    0.12,
    1.53
  );
          animation-timing-function: cubic-bezier(
    0.17,
    0.67,
    0.12,
    1.53
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; /* or: backwards, both, none */
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; /* or: Xms */
  margin-bottom: 15px;
}
.profile__card .profile__image {
  width: 100px;
  height: 100px;
  border-radius:  10% 0  0 10%;
  overflow: hidden;
  -webkit-border-radius:  10% 0  0 10%;
  -moz-border-radius:  10% 0  0 10%;
  -ms-border-radius:  10% 0  0 10%;
  -o-border-radius:  10% 0  0 10%;
}
.profile__card .profile__image img {
  object-fit: cover;
  max-width: 100%;
}
.profile__card p {
  margin-bottom: 0;
  margin-left: 10px;
  font-weight: bolder;
}
.username{
  word-wrap: break-word;
  width: 100px;
  text-align: center;
}
@media screen and (min-width: 768px){
  .username{
    width: 200px;
  }
  .profile__card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.avatarE {
  width: 100%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .avatarE {
    width: 60%;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}

.avatarE img {
  border-radius: 50%;
  max-width: 100%;
  object-fit: cover;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.avatarE img:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.corssal div:first-child button {
  background: #fff;
  font-size: 30px;
}
@media (max-width: 768px) {
  .corssal div:first-child button:last-child,
  .corssal div:first-child button:first-child {
    display: none;
  }
}
.corssal div:first-child button:hover {
  background: transparent;
  color: #0081cf;
}
.rec .rec-pagination button{
  background-color: rgb(203, 219, 236);
}
.sel {
  border: 3px solid black;
}

.backgroundImage {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/c3.0aeb6c0b.jpg);
  min-height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

.register {
  width: 90vw;
  height: 90vh;
  position: fixed;
  padding: 30px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .backgroundImage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/c1.f2012e45.jpg);
  }
}

@media screen and (min-width: 1200px) {
  .register {
    width: 40vw;
    height: 60vh;
  }
}
.User_Enter {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.registeInput {
  padding: 10px;
  font-size: 1.2em;
  border: none;
  outline: none;
  border-bottom: 2px solid #2c73d2;
  margin-bottom: 10px;
}
.registeconnect {
  border: none;
  padding: 12px;
  background: #2c73d2;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 1px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
@media screen and (min-width: 992px) {
  .registeconnect:hover {
    cursor: pointer;
    background-color: #845ec2;
  }
}

