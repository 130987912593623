.backgroundImage {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../src/images/c3.jpg");
  min-height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

.register {
  width: 90vw;
  height: 90vh;
  position: fixed;
  padding: 30px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .backgroundImage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../../src/images/c1.jpg");
  }
}

@media screen and (min-width: 1200px) {
  .register {
    width: 40vw;
    height: 60vh;
  }
}
.User_Enter {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.registeInput {
  padding: 10px;
  font-size: 1.2em;
  border: none;
  outline: none;
  border-bottom: 2px solid #2c73d2;
  margin-bottom: 10px;
}
.registeconnect {
  border: none;
  padding: 12px;
  background: #2c73d2;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  margin-left: 1px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
@media screen and (min-width: 992px) {
  .registeconnect:hover {
    cursor: pointer;
    background-color: #845ec2;
  }
}
