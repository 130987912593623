.main__chatbody {
  flex-grow: 1;
  background-color:rgb(244, 243, 248,40%);
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  color: white;
}
@media screen and (min-width: 768px){
  .main__chatbody {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 992px){
  
}

@media screen and (min-width: 1200px){

}
