.main__chatlist {
  margin-bottom: 20px;
  border-bottom: 2px solid #858588;
  
}
.main__chatlist.open .chatlist__items {
  display: block;
}
@media screen and (min-width: 768px) {
  .main__chatlist {
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
    border-bottom: none;
    min-height: 71vh;
  }
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1200px) {
}
.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #080808;
  outline: none;
}
.clicked {
  background: hsl(0, 81%, 69%);
  color: #fff;
}
.chatlist__items {
  overflow: auto;
  display: none;
  height: auto;
  max-height: 30vh;
}
.empty_message_show {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242425;
  font-size: larger;
  font-weight: bolder;
  padding: 30px;
}
@media screen and (min-width: 768px) {
  .chatlist__items {
    min-height: calc(100vh - calc(100vh / 2));
    max-height: calc(100vh - calc(100vh / 2));
  }
}

@media screen and (max-width: 768px) {
  .empty_message_show {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #ebe7fb;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: bolder;
  font-size: 17px;
  margin-bottom: 3px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
  font-weight: bolder;

}
.chatlist__item:hover,
.chatlist__item.active {
  background: #fff;
  border-radius: 10px;
}
.chatlist__item.new {
  background: #a82929;
  border-radius: 10px;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
